.homeNavbar {
    z-index: 99;
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    overflow: hidden;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            
    .popUpContainer {
        position: fixed;
        z-index: 99;
        background-color: rgba(0, 0, 0, 0.9);
        height: 100%;
        width: 100%;
        
        .details{
            margin-top: 100px;
        }

        p{
            height: 30px;
            cursor: pointer;
            margin-left: auto;
            margin-right: auto;
            width: 50%;
            padding: 10px;
            font-size: 20px;
            margin-bottom: 20px;
            transition: "all 0.2s ease";
            padding: "10px";
            text-align: center;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            border-radius: 10px;
            background-color: white;
            color: #0071bd;
        }

        p:hover{
            background-color: #e4e4e4;
        }

        .closeButton {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            border-radius: 10px;
        }
    }

    .top {
        display: flex;
        justify-content: space-between;

        .topRight {
            display: flex;
            align-self: center;
            gap: 20px;

            .sectionButton {
                margin-right: 20px;
                white-space: nowrap;
                cursor: pointer;
                color: #0071bd;

                &.active {
                    font-weight: bold;
                    font-size: 16px;
                    text-decoration: underline;
                    text-decoration-thickness: 3px;
                    text-underline-offset: 6px;
                }
            }

        }
        
        .hamburger{
            display: none;
            color: #0071bd;

            .icon{
                cursor: pointer;
                transform: scale(1.3);
            }
        }
        

        .topLeftSmallLogo{
            display: none;
        }
        
        @media screen and (max-width: 1200px) {
            .topRight{
                display: none;
            }

            .hamburger{
                display: flex;
                align-self: center;
                margin-right: 30px;
                gap: 10px;

                p{
                    color: #0071bd;
                    font-size: 18px;
                }
            }
            
            .topLeftSmallLogo{
                display: none;
            }

            .topLeftBigLogo{
                display: block;
            }
        }

        
        @media screen and (max-width: 550px) {
            .topRight{
                display: none;
            }

            .topLeftSmallLogo{
                display: block;
            }

            .topLeftBigLogo{
                display: none;
            }
        }
    }
}