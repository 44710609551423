.carouselContainer{
    flex: 4;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

    padding: 10px;
    color: gray;

    .control-arrow {
        height: 50%;
        background-color: #0071bd !important;
        transform: translateY(50%);
    }

    .legend{
        transform: translateY(100%);
        background: #0071bd !important;
    }

    .thumb.selected{
        border: 3px solid #0071bd !important;
    }

    .thumb:hover{
        border: 3px solid #0071bd !important;
        
    }
}