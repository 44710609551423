.app.dark {

    background-color: #111;
    color: rgb(156, 156, 156);

    h1,
    h3 {
        color: rgb(200, 200, 200);
    }

    p,
    li,
    ul {
        color: rgb(156, 156, 156);
    }

    .homeContainer {
        div {
            background-color: #111;
        }
    }

    .chartGrid {
        stroke: rgba(228, 228, 228, 0.2);
    }

    .table {
        background-color: #111;

        .tableCell {
            color: gray;
        }
    }

    .productContainer {
        .productFilter {
            .inputBox {
                background-color: gray;
                color: black;
            }
        }
    }


    .selectComboBoxWrap {
        background: gray !important;
    }

    .orderContainer {
        .filterContainer {

            .rdrDateRangePickerWrapper,
            .calendarWrap {
                background: gray !important;
            }

            .inputBox {
                background: gray !important;
                color: black !important;
            }
        }
    }

    button {
        color: gray !important;
        background-color: black !important;
        border-color: gray !important;
        border: 1px solid gray;

        &:hover {
            background-color: rgba(128, 128, 128, 0.3) !important;
            transition: all 0.2s ease;
        }
    }

    textarea {
        background: gray !important;
        color: black !important;
    }

    .reportContainer {
        .filterContainer {

            .rdrDateRangePickerWrapper,
            .calendarWrap {
                background: gray !important;
            }

            .inputBox {
                background: gray !important;
                color: black !important;
            }
        }
    }

    .navbar {
        color: #999;
        border-color: #333;

        .darkmodeicon {
            visibility: collapse;
        }

        .lightmodeicon {
            visibility: visible !important;
            color: wheat;
        }

        .search {
            border-color: gray;
            color: white;
        }

        .notificationContainer {
            background-color: black !important;

            button {
                background-color: black !important;
                color: gray !important;
                border: 1px solid gray;

                &:hover {
                    background-color: rgba(128, 128, 128, 0.3) !important;
                    transition: all 0.2s ease;
                }
            }
        }
    }

    .sidebar {
        background-color: #111;
        border-color: #333;

        hr {
            border: none;
            border-top: 1px solid #333;
        }
    }

    .featured {
        .summary {
            .targetInput {

                color: gray;
            }
        }
    }

    .feedbackContainer {

        .feedbackButton {
            color: gray !important;
            background-color: black !important;
            border-color: gray !important;
            border: 1px solid gray;

            &:hover {
                background-color: rgba(128, 128, 128, 0.3) !important;
                transition: all 0.2s ease;
            }
        }
    }

    .orderContainer {
        .calendarWrap {
            background-color: rgba(255, 255, 255, 0.3);
        }


        .statusFilter {
            .comboBoxWrap {
                background-color: rgba(255, 255, 255, 0.3);

                .selectComboBoxWrap {
                    background-color: rgba(255, 255, 255, 0.3) !important;

                    .menuItem {

                        background-color: rgba(255, 255, 255, 0.3);
                    }
                }
            }
        }
    }

    .datatable {

        .MuiTablePagination-root,
        .MuiIconButton-root {
            color: gray !important;
        }

        .datatableTitle {
            .link {
                color: gray;
                border: 1px solid gray;

                &:hover {
                    background-color: rgba(128, 128, 128, 0.3);
                    transition: all 0.2s ease;
                }
            }

            .linkContainer {
                .addNewLink {

                    border: 1px solid gray;
                    color: gray;
                }

            }

            .exportButton {
                color: gray;
                border: 1px solid gray;

                &:hover {
                    background-color: rgba(128, 128, 128, 0.3);
                    transition: all 0.2s ease;
                }
            }
        }

        .datagrid {
            color: gray;
            border: none;

            background-color: rgba(128, 128, 128, 0.3);

            .viewButton,
            .deleteButton {
                color: gray;

                &:hover {
                    background-color: rgba(128, 128, 128, 0.4);
                    transition: all 0.2s ease;
                }
            }

            .cellWithStatus {
                color: gray;
            }
        }
    }

    .ProseMirror,
    input {
        color: gray;
        background-color: transparent;
    }

    input::placeholder {
        color: gray;
    }

}