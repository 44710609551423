.barcodeScanner {
    display: flex;
    height: 100%;
    width: 100%;

    .barcodeScannerContainer {
        flex: 6;
        padding-bottom: 300px;

        .top {
            margin: 20px;

            .barcodeScannerTitle {
                margin-left: 20px;
                color: gray;
                width: 100%;
                font-size: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .barcodeScannerSubTitle{
                color: gray;
                font-style: italic;
                margin-left: 40px;
            }

            .main {
                .datatable{
                    margin-bottom: 60px;
                }

                .bottomContainer{
                    width: 100%;
                    display: flex;
                    flex-wrap: nowrap;
                    
                    .bottomDetails{
                        width: 40%;
                        .bottomIndividualContainer{
                            margin-top: 20px;
                            display: flex;
                            gap: 20px;
                        }
                        margin: 10px;
                        padding: 20px;
                        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                        border-radius: 10px;

                    }

                    .groupDetails{
                        padding: 20px;

                    }
                    
                    .bottomDetails.group{
                        width: 100%;

                        .datatable{
                            height: 300px !important;
                        }
                    }
                }

                .middleContainer{
                    margin-top: 20px !important;
                    margin: 10px;
                    padding: 20px;
                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    border-radius: 10px;

                    .middleContainerDetails{
                        display: flex;
                        justify-content: space-between;
                        width: 50%;
                    }
                    
                    .datatable{
                        height: 150px !important;
                    }
                }


                .scannerContainer {
                    .topScannerContainer{
                        display: flex;
                    }
                    
                    margin: 10px;
                    padding: 20px;
                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    border-radius: 10px;


                    .scannerDetails {
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        margin: 20px;
                        gap: 10px;

                        .inputBoxFocus .tooltipText{
                            color: red;
                            background-color: #ff838325;
                            width: fit-content;
                            pointer-events: none;
                            position: relative;
                            top: 20px;
                            left: 5px;
                            user-select: none;
                            font-size: 12px;
                        }
                    }

                    .statusFilter {
                        align-items: center;
                        margin: 20px;
                        gap: 10px;
                        display: flex;
                    }

                    .barcodeScannerFilter {
                        input {
                            margin-top: 8px;
                            height: 34px;
                            max-width: 130px;
                        }

                    }

                    .bottomIndividualContainer {
                        align-items: center;
                        margin: 20px;
                        gap: 20px;
                        display: flex;
                    }
                }
            }

        }
    }
}