.singleBlogContainer {
    height: 90%;
    width: calc(100% - 50px);
    align-content: center;
    color: black;

    margin: 20px;
    padding: 20px;

    .info {
        margin-top: 50px;
        display: flex;
        gap: 20px;
        color: #0071bd;

        .author {

            margin-top: 5px;
            display: flex;
            align-items: center;
            gap: 5px;

        }

        .time {
            margin-top: 5px;
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }

    .thumbnail {
        max-width: fit-content;
        width: 50%;
    }


    .content {

        ol,
        ul,
        h3,
        h4,
        p {
            margin-top: 10px;
        }

        img {
            max-width: fit-content;
            width: 50%;
            margin: 10px;
            padding: 10px;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            border-radius: 10px;
        }


    }

    .bottom {
        .likes {
            display: flex;
            flex-wrap: wrap;

            p {
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        .icon {
            cursor: pointer;
            margin-left: 10px;
            margin-right: 10px;
            color: #0071bd;
        }

        .feedback {
            width: 100%;

            textarea {
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 600px) {

        .thumbnail,
        img {
            width: calc(100% - 50px) !important;
        }
    }

}