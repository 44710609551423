.homeContainer {

    .middle {
        height: 100%;
        width: 100%;
        margin-top: 60px;
        padding: 20px;
        align-content: center;
        color: #0071bd;

        .carousel{
            margin-top: 20px !important;
            width: calc(80% - 40px);
            margin-left: auto;
            margin-right: auto;
        }
        
        @media only screen and (max-width: 800px) {
            .carousel{
                width: calc(100% - 40px);
                margin-left: 0;
                margin-right: 0;
            }
        }

        .blogContainer {

            .carousel {
                margin-top: -20px;
            }

            .filterContainer {
                width: calc(100% - 40px);
                margin-top: 20px;
                margin-bottom: 20px;
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                .filter {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 10px;
                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    border-radius: 10px;

                    input {
                        margin-left: 10px;
                        background-color: white;
                        color: #0071bd;
                    }
                }

                .category {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 20px;

                    .categories {
                        gap: 20px;
                        display: flex;
                        flex-wrap: wrap;
                    }

                    .categoryButton {
                        margin-top: auto;
                        margin-bottom: auto;
                        margin-left: 10px;
                        margin-right: 10px;
                        white-space: nowrap;
                        cursor: pointer;
                        color: #0071bd;

                        &.active {
                            font-weight: bold;
                            font-size: 16px;
                            text-decoration: underline;
                            text-decoration-thickness: 3px;
                            text-underline-offset: 6px;
                        }
                    }
                }

            }

            .recentBlogs {
                width: calc(100% - 40px);
                flex-flow: wrap;
                display: flex;
                gap: 20px;

                .blog {
                    width: calc(50% - 30px);
                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    border-radius: 10px;
                    padding: 10px;

                    @media only screen and (max-width: 600px) {
                        width: 100%;
                    }

                    img {
                        width: 100%;
                    }

                    .title {
                        margin-top: 5px;
                    }

                    .category {
                        margin-top: 5px;
                        background-color: #0071bd30;
                        width: fit-content;
                        border-radius: 5px;
                        padding: 5px;
                    }

                    .time {
                        margin-top: 5px;
                        display: flex;
                        align-items: center;
                        gap: 5px;
                    }
                }

                .blog:hover {
                    background-color: rgba(128, 128, 128, 0.3);
                    transition: all 0.2s ease;
                }

            }
        }
    }
}