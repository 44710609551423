.homeContainer {
    .contactUsContainer {
        margin: 20px;
        padding: 20px;

        .location {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            padding-top: 20px;

            .left {

                iframe {
                    width: 60vw;
                    height: 50vw;
                }

                @media screen and (max-width: 1300px) {
                    iframe {
                        width: 80vw;
                        height: 50vw;
                    }

                }
            }

            .right {
                .details {
                    padding-top: 5vw;
                }
            }
        }
    }
}