.feedback {
    display: flex;
    height: 100%;
    width: 100%;

    .feedbackContainer {
        flex: 6;
        overflow-y: auto;
        padding-bottom: 50px;

        .popUpContainer {
            display: flex;
            width: 100%;
            height: 500px;
            padding-bottom: 30px;
            padding-top: 10px;

            .top {
                display: flex;
                margin-bottom: 20px;
            }

            .closeButton {
                cursor: pointer;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                border-radius: 10px;
            }

            .feedbackForm{
                height: 400px;
            }
        }

        .top {
            margin: 20px;

            .feedbackSubTitle {
                color: gray;
                font-style: italic;
                margin-left: 40px;
            }

            .feedbackTitle {
                color: gray;
                width: 100%;
                font-size: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .feedbackButton{
                margin-top: 20px;
                cursor: pointer;
                font-size: 20px;
                border-color: transparent;
                background-color: white;
                text-decoration: none;
                border-radius: 10px;
                padding: 5px 10px 5px 10px;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

                &:hover {
                    background-color: rgba(128, 128, 128, 0.3);
                    transition: all 0.2s ease;
                }
            }
        }
    }
}