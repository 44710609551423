.chart{
    flex: 4;
    min-width: 500px;

    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    padding: 10px;
    color: gray;

    .title{
        margin-bottom: 20px;
            font-weight: bold;
            font-size: 13px;
            color:rgb(160,160,160);
    }
}