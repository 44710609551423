.tiptapContainer {

    .ProseMirror{
        outline: 3px solid gray;
        border-radius: 3px;
        padding: 10px;
        min-height: 300px;
    }

    .ProseMirror:focus {
        outline: 3px solid rgb(187, 187, 187);
    }

    >*+* {
        margin-top: 0.75em;
    }

    .menuBar{
        display: flex;
        flex-wrap: wrap;

        .formatDropDown{
            padding: 5px;
            select{
                .h1{	
                    font-size: 2em;
                    font-weight: bolder;
                }
                .h2{	
                    font-size: 1.5em;
                    font-weight: bolder;
                }
                .h3{
                    font-size: 1.17em;
                    font-weight: bolder;
                }
                .p{
                    font-size: 1em;
                }
            }
        }
        .muiIcon{
            cursor: pointer;
            padding: 5px;
        }
        .account-selector-collapsed::-ms-expand {
            display: none;
        }
        
        .muiIcon:hover{
            background-color: rgba(128, 128, 128, 0.3);
            transition: all 0.2s ease;
        }
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
    }

    pre {
        background: #0D0D0D;
        color: #FFF;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0D0D0D, 0.1);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(#0D0D0D, 0.1);
        margin: 2rem 0;
    }
}