.homeContainer {
    .loginSignupContainer {
        width: calc(100% - 60px);
        display: flex;
        align-content: center;

        @media screen and (max-width:1200px) {
            display: block;
            min-width: 250px;

            .left {
                width: 100% !important;
            }

            img {
                max-width: 100% !important;
            }
        }

        .left,
        .right {
            margin: 20px;
            padding: 20px;
        }

        .left {
            margin-left: auto;
            width: 60%;

            .top {
                img {
                    width: 48%;
                    padding: 5px;
                }
            }

            .bottom {
                img {
                    width: calc(100% - 60px);
                }
            }
        }

        .right {
            background-color: rgb(250, 250, 250);

            .tabContent {

                .loginTab,
                .signupTab {
                    min-height: 600px;
                }
            }

            input {
                height: 30px;
            }

            .forgotPassword {
                cursor: pointer;
                font-weight: bolder;
            }

            .signupTab {
                display: none;
            }

            .toggleButton.text {
                cursor: pointer;
                font-weight: bolder;
            }

            .tab {
                display: flex;
                justify-content: space-between;
                gap: 40px;

                .active {
                    background-color: #0071bd94;
                    border: 3px solid #0071bd;
                }

            }

            button {
                width: 100%;
                padding: 10px;
                border: none;
                background-color: #0071bd;
                color: #0071bd;
                font-weight: bold;
                cursor: pointer;

                &:hover {
                    background-color: #0071bd94;
                    transition: all ease 0.2s;
                }
            }

            p {
                text-align: center;

                .logo {
                    height: 150px;
                }
            }

            .title {
                text-align: center;
                margin-bottom: 50px;
            }

            input {
                width: 100%;
            }

            div {
                margin-bottom: 20px;
                text-align: left;
            }

            a {
                text-decoration: none;
            }

            flex: 2;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        }
    }
}