
.dashboardHome{
    .tables
    {
        margin: 20px;
        justify-content: space-between;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        border-radius: 10px;
        padding-bottom: 40px;

        .datatable {
            height: 500px;
            margin-bottom: 10px;

            .datatableTitle{
            font-weight: bold; 
            font-size: 13px;
            color:rgb(160,160,160);
            }
        }
    }
}

