.homeContainer {
    .servicesContainer {
        margin-top: 90px !important;
        color: #0071bd;
        margin: 30px;
        align-content: center;
        align-self: center;
        text-align: center;

          .preview{
            padding: 10px;
            max-width: 800px;
            width: 100%;
          }

        .innerTitle{
          display: none;
        }

        .title {
            padding-top: 10%;
            padding-bottom: 20%;
            background-image: url("../../../materials/about_us.jpg");
            background-size: cover;
            background-position: 50%;

            .details {
                text-align: center;
                background-color: rgba(255, 255, 255, 0.7);
            }
        }

        .story {
            padding-top: 20px;

            .images {
                display: flex;

                img {
                    padding: 10px;
                    width: 45%;
                }
            }
        }

        * {
            box-sizing: border-box;
          }
          
          .cloudColumns {
            float: left;
            width: 50%;
            padding-top: 20px;
            padding-bottom: 20px;
          }

          .windowsColumns {
            float: left;
            width: 33%;
            padding-top: 20px;
            padding-bottom: 20px;
          }
          
          /* Style the list */
          .price {
            list-style-type: none;
            border: 1px solid #eeeeee;
            margin: 0;
            padding: 0;
            -webkit-transition: 0.3s;
            transition: 0.3s;
          }
          
          /* Add shadows on hover */
          .price:hover {
            position: relative;
            -webkit-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 1);
            box-shadow: 0px 0px 5px 5px rgba(201, 201, 201, 1);
            li{
                text-shadow: 1px 0.4px 0 black;
                -webkit-transition: all .4s;
                -moz-transition: all .4s;
                -o-transition: all .4s;
                transition: all .4s;
            }
          }
          
          /* Pricing header */
          .price .header {
            background-color: #0071bd;
            color: white;
            height: 280px;
          }
          
          /* List items */
          .price li {
            height: 55px;
            border-bottom: 1px solid #eee;
            padding: 20px;
            text-align: center;

            img{
                height: 40px;
                position: relative;
                bottom: 12px;
            }
          }
          
          /* Grey list item */
          .price .grey {
            border: 1px solid #eee;
            background-color: #eee;
            font-size: 20px;
          }
          
          /* The "Sign Up" button */
          .button {
            background-color: #04AA6D;
            border: none;
            color: white;
            padding: 10px 25px;
            text-align: center;
            text-decoration: none;
            font-size: 18px;
          }
          
          @media only screen and (max-width: 1230px) {
            .innerTitle{
              display: block;
            }

            .cloudColumns, .windowsColumns {
              width: 100%;
            }

            
          .price .header {
            height: fit-content
          }

            .cloudTitleColumns, .windowsTitleColumns{
              display: none;
            }
            
            .price li {
              height: 90px;

              img{
                  bottom: 0px;
              }
            }
          }

    }
}