.homeContainer {

    .termsContainer {

        width: calc(100% - 60px);
        padding: 20px;

        .section {
            padding-top: 10px;

            a,
            p {
                padding-left: 10px;
            }

            a {
                display: block;
                width: fit-content;
            }
        }
    }
}