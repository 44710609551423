.homeContainer {
    .aboutUsContainer {
        margin-top: 80px !important;
        color: #0071bd;
        margin: 30px;
        align-content: center;
        align-self: center;
        text-align: center;

        .title {
            padding-top: 10%;
            padding-bottom: 20%;
            background-image: url("../../../materials/about_us.jpg");
            background-size: cover;
            background-position: 50%;

            .details {
                text-align: center;
                background-color: rgba(255, 255, 255, 0.7);
            }
        }

        .story {
            padding-top: 20px;

            .images {
                display: flex;

                img {
                    padding: 10px;
                    width: 45%;
                }
            }
        }

    }
}