html, body, #app, #app>div {
    width: 100%;
    height: 100%;
    background-color: white;
  }

.MuiDataGrid-row {
    margin-top: 5px;
}

.viewButton{
    margin-bottom: 5px;
}


.MuiPaper-root {
    background-color: rgb(228, 228, 228) !important;
}

.MuiTypography-root,
.MuiDialogContent-root {
    background-color: rgb(210, 210, 210) !important;
}

.MuiFormControlLabel-label
{
    background-color: transparent !important;
}
.rdrStaticRange, .rdrInputRanges, .rdrDefinedRangesWrapper, 
.rdrCalendarWrapper, .rdrDateDisplayWrapper{
    background-color: rgb(228, 228, 228) !important;
}

button{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    font-size: 20px;
    border-color: transparent;
    background-color: white !important;
    text-decoration: none;
    border-radius: 10px !important;
    padding: 5px 10px 5px 10px !important;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47) !important;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47) !important;
}

button:hover {
    background-color: rgba(128, 128, 128, 0.3) !important;
    transition: all 0.2s ease;
}

    
.ProseMirror, input {
    color: black;
    background-color: transparent;
}

input::placeholder{
    color: black;
}

.tiptap-image-class{
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 50% !important;
    max-height: 300px;
    display: flex;
    margin-right: auto;
    max-width: max-content !important;
}

.tiptap-link-class{
    padding: 5px;
    border-radius: 5px;
    background-color: #0071bd28;
    cursor: pointer;
    text-decoration: underline !important;
    color: purple !important;
}