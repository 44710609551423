.homeContainer {
    overflow: hidden;
    padding-right: 30px;

    .middle {
        min-width: 300px;
        height: 100%;
        width: 100%;
        align-content: center;
        color: #0071bd;

        .middleHomeContainer {

            margin: 5px;

            .middleHomeTop {
                align-content: center;
                img {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    width: 60%;
                }
            }


            .features {
                display: flex;
                flex-wrap: wrap;

                .feature {
                    width: 40%;
                    padding: 10px;
                    margin: 10px;
                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

                    .title {
                        margin-bottom: 10px;
                        font-size: 20px;
                        font-weight: bold;
                    }

                    .details {
                        font-size: 14px;
                    }
                }
            }
            
            .partners {
                display: flex;
                flex-wrap: wrap;

                .partner {
                    width: 25%;
                    padding: 10px;
                    margin: 10px;
                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);


                    .title {
                        margin-bottom: 10px;
                        font-size: 20px;
                        font-weight: bold;
                    }

                    .details {
                        font-size: 14px;
                    }
                }
            }
            

            @media screen and (max-width: 1250px) {
                .partner{
                    width: 40% !important;
                }
            }
            
            @media screen and (max-width: 1000px) {
                .feature{
                    width: 100% !important;
                }
                
                .partner{
                    width: 100% !important;
                }
            }

        }
    }
}