.homeFooter{
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

    .copyright{
        color: #0071bd;
        text-align: center;

        p{
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}