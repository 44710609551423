.authorization {
    display: flex;
    height: 100%;
    width: 100%;

    .authorizationContainer {
        flex: 6;
        overflow-y: auto;
        padding-bottom: 50px;

        .popUpContainer {
            display: flex;

            .top {
                display: flex;
            }

            .closeButton {
                cursor: pointer;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                border-radius: 10px;
            }
        }

        .top {
            margin: 20px;

            .authorizationSubTitle {
                color: gray;
                font-style: italic;
                margin-left: 40px;
            }

            .authorizationTitle {
                color: gray;
                width: 100%;
                font-size: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .logoContainer {
                padding-top: 10px;
                display: flex;

                .logo {
                    background-color: rgba(255, 255, 255, 0.3); 
                    margin: 10px;
                    cursor: pointer;

                    &:hover {
                        background-color: rgba(128, 128, 128, 0.3);
                        transition: all 0.2s ease;
                    }

                    height: 100%;

                    img {
                        height: 50px;
                        padding: 10px 10px 10px 10px;
                        vertical-align: middle;
                    }

                    text-align: center;
                    flex: 4;
                    margin: 0px 10px 0px 10px;

                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    border-radius: 10px;
                }

                .logoInactive{
                    filter: grayscale(100%);
                    background-color: rgba(200, 200, 200, 0.3); 
                    margin: 10px;

                    &:hover {
                        background-color: rgba(128, 128, 128, 0.3);
                        transition: all 0.2s ease;
                    }

                    height: 100%;

                    img {
                        height: 50px;
                        padding: 10px 10px 10px 10px;
                        vertical-align: middle;
                    }

                    text-align: center;
                    flex: 4;
                    margin: 0px 10px 0px 10px;

                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    border-radius: 10px;
                }

            }


        }
    }
}