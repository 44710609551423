.datatable {
    height: 630px;
    padding: 20px;

    .highlight{
        background-color: rgba(0, 153, 255, 0.3);
    }

    .datatableTitle {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 100%;
        font-size: 24px;
        color: gray;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .buttonSections {
            display: flex;
            gap: 20px;

            .exportButton {
                font-size: 20px;
                color: black;
                text-decoration: none;
                border-radius: 10px;
                padding: 5px 10px 5px 10px;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

                &:hover {
                    background-color: rgba(128, 128, 128, 0.3);
                    transition: all 0.2s ease;
                }
            }

            .addNewLink {
                font-size: 20px;
                color: black;
                text-decoration: none;
                border-radius: 10px;
                padding: 5px 10px 5px 10px;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                cursor: pointer;

                &:hover {
                    background-color: rgba(128, 128, 128, 0.3);
                    transition: all 0.2s ease;
                }
            }
        }

    }

    .cellWithImg {
        display: flex;
        align-items: center;

        .cellImg {
            width: 45px;
            height: 45px;
            //border-radius: 50%;
            object-fit: cover;
            //margin-right: 20px;
        }

        .cellBiggerImg{
            width: 150px;
            height: 150px;
            //border-radius: 50%;
            object-fit: cover;
            //margin-right: 20px;
        }
    }

    .cellWithStatus {
        padding: 5px;
        margin-bottom: 5px;
        border-radius: 5px;

        &.active, &.syncing, &.open {
            background-color: rgba(97, 255, 97, 0.7);
            color: gray !important;
        }

        &.pending, &.pending-review, &.stop, &.processing{
            background-color: rgba(255, 219, 16, 0.7);
            color: gray !important;
        }

        &.passive, &.error {
            background-color: rgba(255, 0, 0, 0.7);
            color: gray !important;
        }
    }

    .cellAction {
        display: flex;
        align-items: center;
        gap: 15px;

        .viewButton {
            padding: 2px 5px;
            border-radius: 5px;
            color: darkblue;
            border: 1px solid gray;
            cursor: pointer;
        }

        .deleteButton {
            padding: 2px 5px;
            border-radius: 5px;
            color: crimson;
            border: 1px solid gray;
            cursor: pointer;
        }
    }

}