.navbar {
    height: 50px;
    border-bottom: 0.5px solid rgb(226, 226, 226);
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .wrapper {
        width: 100%;
        padding: 20px;

        .search {
            display: flex;
            align-items: center;
            border: 0.5px solid lightgray;
            padding: 3px;

            input {
                border: none;
                outline: none;
                background: transparent;

                &::placeholder {
                    font-size: 12px;
                }
            }
        }

        .beta{
            position: absolute;
        }
        .items {
            display: flex;
            float: right;
            align-items: center;

            .item {
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: center;
                margin-right: 10px;

                .circle {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    border-radius: 50%;
                    font-size: 20px;
                    color: #fff;
                    text-align: center;
                    background: #0071bd5d;
                    margin-right: 5px;
                  }
                
                a, a:visited, a:hover, a:active {
                    color: inherit;
                  }

                .notificationContainer {
                    cursor: auto;
                    z-index: 98;
                    height: 400px;
                    overflow-y: scroll;
                    border-radius: 10px;
                    background-color: white;

                    .logo {
                        padding-top: 5px;
                        align-items: center;
                        height: 60px;
                    }

                    button {
                        cursor: pointer;
                        background-color: white;
                        position: absolute;
                        bottom: 20px;
                        font-size: 20px;
                        color: black;
                        border-color: transparent;
                        text-decoration: none;
                        border-radius: 10px;
                        padding: 5px 10px 5px 10px;
                        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

                        &:hover {
                            background-color: rgba(128, 128, 128, 0.3);
                            transition: all 0.2s ease;
                        }
                    }

                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    position: absolute;
                    right: 0;
                    top: 50px;
                    width: 350px;
                    padding: 30px;

                    .notificationDetails {
                        cursor: pointer;
                        display: flex;

                        &:hover {
                            cursor: pointer;
                            background-color: rgba(128, 128, 128, 0.3);
                            transition: all 0.2s ease;
                        }

                        .right {
                            cursor: pointer;
                            padding-bottom: 5px;

                            .notificationTitle {
                                cursor: pointer;
                                font-size: 18px;
                                font-weight: bold;
                            }

                            .notificationBody {
                                cursor: pointer;
                                font-style: italic;
                                font-size: 13px;
                            }

                            .notificationTime {
                                cursor: pointer;
                                font-size: 10px;
                            }
                        }

                        .bottom {
                            align-self: flex-end;

                            .unseen {
                                width: 20px;
                                height: 20px;
                                background-color: red;
                                border-radius: 50%;
                                color: white;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 10px;
                                font-weight: bold;
                            }
                        }
                    }
                }

                .lightmodeicon,
                .darkmodeicon {
                    font-size: 35px;
                    left: -40px;
                    position: absolute;

                    &:hover {
                        border-radius: 10px;
                        background-color: rgba(128, 128, 128, 0.3);
                        transition: all 0.2s ease;
                    }
                }

                .icon {
                    font-size: 35px;

                    &:hover {
                        border-radius: 10px;
                        background-color: rgba(128, 128, 128, 0.3);
                        transition: all 0.2s ease;
                    }
                }

                .avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }

                .counter {
                    z-index: 99;
                    width: 20px;
                    height: 20px;
                    background-color: red;
                    border-radius: 50%;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    font-weight: bold;
                    position: absolute;
                    top: -3px;
                    right: -3px;
                }
            }


        }

    }
}