.new {
    width: 100%;
    display: flex;

    .newContainer {
        flex: 6;


        .top{
            padding: 20px;
            margin: 20px;
        }

        .bottom {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 20px;
            display: flex;


            .left {
                flex: 1;
                text-align: center;

                img {
                    padding-top: 10px;
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .right {
                flex: 2;

                form {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 25px;

                    .formInput {
                        width: 40%;

                        label {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .icon {
                                background: rgba(128, 128, 128, 0.3);
                                border-radius: 50%;
                                padding: 5px;
                                cursor: pointer;
                            }


                            .icon:hover {
                                background: rgba(128, 128, 128, 0.7);
                                transition: all ease 0.2s;
                            }
                        }

                        input {
                            width: 100%;
                            padding: 5px;
                            border: none;
                            border-bottom: 1px solid gray;
                        }
                    }

                    button {
                        width: 100%;
                    }
                }
            }
        }

        
        .transferHistory {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 20px;
        }
    }

}