.shipment {
    display: flex;
    height: 100%;
    width: 100%;

    .shipmentContainer {
        flex: 6;
        padding-bottom: 300px;

        .top {
            margin: 20px;

            .shipmentSubTitle {
                color: gray;
                font-style: italic;
                margin-left: 40px;
            }

            .shipmentTitle {
                margin-bottom: 20px;
                color: gray;
                width: 100%;
                font-size: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .filterContainer {
                padding: 20px;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                border-radius: 10px;

                .details {
                    margin-top: 5px;
                    display: flex;

                    p {
                        min-width: fit-content;
                    }

                    button {
                        margin-top: 5px;
                        margin-bottom: 5px;
                        margin-left: 10px;
                        font-size: 15px;

                        &.active {
                            background-color: #0071bd5d !important;
                        }
                    }
                }
            }

            .main {
                display: flex;

                .detailsContainer,
                .orderContainer {
                    width: 100%;
                    margin-bottom: 20px;
                    padding: 20px;
                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    border-radius: 10px;

                    .platformFilter {
                        display: flex;
                        align-items: center;
                        margin: 20px;
                        gap: 10px;
                    }

                    .orderFilter {
                        input {
                            margin-top: 8px;
                            height: 34px;
                        }

                    }

                    .generateContainer {
                        min-width: 330px;
                        display: flex;
                        align-self: center;
                        justify-content: space-between;

                        p {
                            padding-top: 15px;
                        }
                    }

                    .details {
                        min-width: 180px;
                        margin-top: 10px;
                        gap: 20px;
                        display: flex;
                    }
                }
            }
        }


        .processContainer {
            padding: 20px;

            button {
                margin-right: 10px;
            }
        }
    }

}

.printShipment {
    display: flex;
    height: 100%;
    width: 100%;

    .printShipmentContainer {
        flex: 6;
        padding-bottom: 300px;

        .printDetails {
            margin-bottom: 40px;
        }

        .printContainer {
            margin: 20px;
        }
    }

}