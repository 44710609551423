.featured{
    flex: 2;
    
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    padding: 10px;
    min-width: 500px;
    max-width: 1000px;

    .top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color:gray;

        .title{
            font-weight: bold;
            font-size: 13px;
            color:rgb(160,160,160);
        }
    }

    .bottom{
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;

        .featuredChart{
            width: 50%;
        }

        .title{
            font-weight: 500;
            color:gray;
            text-align: center;
        }

        .amount{
            font-size: 30px;
            text-align: center;
        }

        .time{
            font-weight: 300;
            font-size: 12px;
            color: gray;
            text-align: center;
        }

        .summary{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .item{
                max-width: 50%;
                text-align: center;

                .itemTitle{
                    font-size: 14px;
                    color: gray;
                }

                .itemResult{
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    font-size: 14px;

                    &.positive{
                        color: green;
                    }

                    &.negative{
                        color: red;
                    }

                    .targetInput{
                        max-width: 40%;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}