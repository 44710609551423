.widget{
    display: flex;
    flex: 1;
    padding: 20px;
    justify-content: space-between;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .left{
        padding-right: 20px;
    }
    
    .left, .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        

        .top{
            font-size: 10px;
            display: flex;
            gap: 5px;
            align-items: center;

        }

        .title{
            font-weight: bold;
            font-size: 13px;
            color:rgb(160,160,160);
        }

        .counter{
            font-size: 26px;
            font-weight: 300;
        }

        .link{
            width: max-content;
            font-size: 12px;
            border-bottom: 1px solid gray;
            cursor: pointer;
        }

        .percentage{
            display: flex;
            align-items: center;
            font-size: 14px;
            
            &.positive{
                color: green;
            }

            &.negative{
                color: red;
            }
        }

        .icon{
            font-size: 18px;
            padding: 5px;
            border-radius: 5px;
            align-self: flex-end;
        }
    }
}