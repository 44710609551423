.account {
    display: flex;
    height: 100%;
    width: 100%;

    .accountContainer {
        flex: 6;
        overflow-y: auto;
        padding-bottom: 50px;

        .top {
            margin: 20px;

            .accountTitle {
                color: gray;
                width: 100%;
                font-size: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .center {

            margin: 20px;

            .body {
                margin-top: 20px;
                
                min-width: 300px;
                div {
                    padding-top: 10px;
                }

                padding: 20px;

                input {
                    height: 30px;
                    width: 50%;
                    font-size: 16px;
                }

                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                border-radius: 10px;

                .circle {
                    width: 300px;
                    height: 300px;
                    line-height: 300px;
                    border-radius: 50%;
                    font-size: 150px;
                    color: #fff;
                    text-align: center;
                    background: #0071bd5d;
                    margin-right: 5px;
                  }

                .profilePicture {
                    display: flex;
                }

                img {
                    width: 200px;
                }
            }


            .accountButton {
                margin-left: 20px;
                cursor: pointer;
                font-size: 20px;
                border-color: transparent;
                background-color: white;
                text-decoration: none;
                border-radius: 10px;
                padding: 5px 10px 5px 10px;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

                &:hover {
                    background-color: rgba(128, 128, 128, 0.3);
                    transition: all 0.2s ease;
                }
            }
        }
    }
}