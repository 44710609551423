.feedbackSingle {
  display: flex;
  height: 100%;
  width: 100%;

  .feedbackSingleContainer {
    flex: 6;
    overflow-y: auto;
    padding-bottom: 50px;

    .top {
      h2 {
        padding-top: 10px;
      }

      .fullChatContainer {
        min-width: 400px;

        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 20px;

        .chat {
          margin-top: 10px;
          height: 50px;
          width: 80%;
        }

        .chatContainer {
          max-height: 500px;
          overflow-y: auto;
          display: flex;
          flex-direction: column-reverse;

          .container {
            border: 2px solid #dedede;
            background-color: #f1f1f1;
            border-radius: 5px;
            padding: 10px;
            margin: 10px;
          }

          img {
            width: 60px;
            height: 60px;
          }

          .tactibots {
            width: 90%;
          }

          /* user chat container */
          .user {
            margin-left: auto;
            color: white;
            margin-right: 0;
            width: 90%;
            border-color: #ccc;
            background-color: #0071bd;
          }

          /* Clear floats */
          .container::after {
            content: "";
            clear: both;
            display: table;
          }

          /* Style images */
          .container img {
            float: left;
            max-width: 60px;
            width: 100%;
            margin-right: 20px;
            border-radius: 50%;
          }

          /* Style the right image */
          .container img.right {
            float: right;
            margin-left: 20px;
            margin-right: 0;
          }

          /* Style time text */
          .time-user {
            font-size: 12px;
            float: right;
          }

          /* Style time text */
          .time-tactibots {
            font-size: 12px;
            float: left;
          }
        }
      }


      .saveButton {
        margin-left: 10px;
        cursor: pointer;
        font-size: 20px;
        border-color: transparent;
        background-color: white;
        text-decoration: none;
        border-radius: 10px;
        padding: 5px 10px 5px 10px;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

        &:hover {
          background-color: rgba(128, 128, 128, 0.3);
          transition: all 0.2s ease;
        }
      }

      margin: 20px;

      .topDetails {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        min-height: 350px;

        .left {
          min-width: 350px;
          flex-wrap: wrap;

          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          border-radius: 10px;
          padding: 10px;
          margin-right: 20px;
          width: 40%;

          .withStatus {
            width: fit-content;
            padding: 5px;
            margin-bottom: 5px;
            border-radius: 5px;

            &.open {
              background-color: rgba(97, 255, 97, 0.7);
              color: gray !important;
            }

            &.pending-review {
              background-color: rgba(255, 219, 16, 0.7);
              color: gray !important;
            }


          }
        }

        .timeline {
          min-width: 350px;
          max-height: 350px;
          overflow-y: scroll;

          width: 60%;
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          border-radius: 10px;
          padding: 10px;

          * {
            box-sizing: border-box;
          }

          /* Set a background color */
          body {
            font-family: Helvetica, sans-serif;
          }

          /* Container around content */
          .container {
            padding: 0px 30px 20px 0px;
            position: relative;
            background-color: inherit;
            width: 90%;
          }

          /* The circles on the timeline */
          .container::after {
            content: '';
            position: absolute;
            width: 15px;
            height: 15px;
            border: 4px solid rgb(187, 187, 187);

            top: 15px;
            border-radius: 50%;
            z-index: 1;
          }

          .content {
            &.last {
              h3 {
                color: #0071bd;
              }

              p {
                color: #0071bd;
              }
            }
          }

          /* Place the container to the right */
          .right {
            left: 10%;
          }

          /* Fix the circle for containers on the right side */
          .right::after {
            left: -35px;
          }

          /* The actual content */
          .content {
            padding: 20px 30px;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.8);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.8);
            position: relative;
            border-radius: 6px;
          }
        }

      }


      .feedbackSingleForm,
      .images {
        margin-bottom: 20px;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        border-radius: 10px;
        padding: 10px;
      }

      .feedbackSingleTitle {
        color: gray;
        width: 100%;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .feedbackSingleButton {
        margin-top: 20px;
        cursor: pointer;
        font-size: 20px;
        border-color: transparent;
        background-color: white;
        text-decoration: none;
        border-radius: 10px;
        padding: 5px 10px 5px 10px;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

        &:hover {
          background-color: rgba(128, 128, 128, 0.3);
          transition: all 0.2s ease;
        }
      }
    }
  }
}