.sidebar {
    flex: 1;
    border-right: 0.5px solid rgb(226, 226, 226);
    min-height: 100vh;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .top {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        
        .logo {
            height: 100%;
        }

        .logoText {
            font-size: 20px;
            font-weight: bold;
            color: #0071bd;
        }
    }

    hr {
        border: none;
        border-top: 1px solid rgb(226, 226, 226);
    }

    .center {
        padding-left: 10px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            .title {
                font-size: 10px;
                font-weight: bold;
                color: #999;
                margin-top: 15px;
                margin-bottom: 5px;
            }

            .active {
                background-color: #b5d2e6;
            }

            li {
                display: flex;
                align-items: center;
                padding: 5px;
                cursor: pointer;

                &:hover {
                    background-color: #80c5f3;
                }

                .icon {
                    font-size: 18px;
                    color: #0071bd;
                }

                span {
                    font-style: 13px;
                    font-weight: bold;
                    color: gray;
                    margin-left: 10px;
                }
            }
        }
    }

    .bottom {
        display: flex;
        align-items: center;
        margin: 10px;

        .colorOption {
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 0.5px solid #0071bd;
            cursor: pointer;
            margin: 5px;

            &:nth-child(1) {
                background-color: whitesmoke;
            }

            &:nth-child(2) {
                background-color: #333;
            }
        }

    }
}