.questions {
    display: flex;
    height: 100%;
    width: 100%;
    color: #0071bd;
    


    a,
    a:visited,
    a:hover,
    a:active {
        color: inherit;
        text-decoration: none;
    }
    
    .author {
        display: flex;
        gap: 10px;
        vertical-align: middle;
        padding: 5px;
        border-radius: 5px;
        background-color: #0071bd28;

        img {
            height: 40px;
        }

        h3 {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .askQuestionContainer {
        .guide {
            margin-bottom: 20px;
            padding: 20px;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        }

        .title {
            margin-bottom: 20px;
            padding: 20px;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

            input {
                width: 100%;
            }
        }

        .details {
            margin-bottom: 20px;
            padding: 20px;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        }

        .tags {
            margin-bottom: 20px;
            padding: 20px;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

            .individualTag {
                display: flex;
                gap: 10px;
                margin-bottom: 10px;

                .singleTag {
                    padding: 5px;
                    background-color: rgba(0, 153, 255, 0.3);
                    border-radius: 5px;
                }
            }

            input {
                width: 100%;
            }
        }
    }

    .questionsContainer {
        overflow-y: auto;

        .questionContainer {
            width: calc(100% - 40px);
            padding: 20px;

            @media screen and (max-width: 700px) {
                .topContainer {
                    display: block !important;

                    button {
                        margin-top: 10px;
                    }
                }

                .secondContainer {
                    display: block !important;

                    button {
                        margin-top: 10px;
                    }
                }
            }

            .topContainer {
                display: flex;
                justify-content: space-between;
                gap: 20px;
            }

            .secondContainer {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;

                button {
                    margin-left: 10px;

                    &.active {
                        background-color: #0071bd5d;
                    }
                }
            }

            .middleTagContainer {
                margin-bottom: 20px;
                display: flex;
                flex-wrap: wrap;



                @media screen and (max-width: 1350px) {
                    .singleTag {
                        width: 350px !important;
                    }
                }

                @media screen and (max-width: 1100px) {
                    .singleTag {
                        width: 200px !important;
                    }
                }

                .singleTag {

                    position: relative;
                    margin-top: 20px;
                    margin-right: 20px;
                    width: 310px;


                    padding: 20px;
                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

                    .details {
                        h3 {
                            margin-bottom: 20px;
                            width: fit-content;
                            border-radius: 5px;
                            padding: 5px;
                            background-color: #0071bd5d;

                        }

                        .description {
                            margin-bottom: 40px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                    }

                    .bottom {
                        position: absolute;
                        bottom: 0;

                        .counts {
                            display: flex;
                            gap: 20px;
                            justify-content: space-between;
                            padding-bottom: 5px;
                        }
                    }
                }
            }

            .middleContainer {
                margin-top: 20px;


                hr {
                    margin-bottom: 20px;
                    border: 1px solid #0071bd;
                }

                .singleQuestion {
                    display: flex;
                    padding-bottom: 10px;
                    gap: 30px;

                    .left {
                        text-align: right;
                        min-width: max-content;

                        .marked{
                            border-radius: 5px;
                            background-color: rgba(97, 255, 97, 0.7);
                        }

                        .item{
                            padding: 5px;
                        }
                    }

                    .right {
                        .tags {
                            display: flex;
                            gap: 10px;
                            padding: 5px;

                            .tag {
                                padding: 5px;
                                border-radius: 5px;
                                background-color: #0071bd28;
                            }
                        }
                    }
                }

                .bottom {
                    margin-right: 20px;
                    display: flex;
                    justify-content: flex-end;
                }

                .bottomTime {
                    margin-right: 20px;
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }

    }

    .singleQuestionContainer {


        hr {
            margin-bottom: 20px;
            border: 1px solid #0071bd;
        }

        .top {
            display: flex;
            justify-content: space-between;
        }

        .second {
            display: flex;
            gap: 20px;
        }

        .question, .singleAnswer{
            display: flex;
            .left{
                width: 50px;
                .item{
                    cursor: pointer;
                    margin-bottom: 10px;
                    padding: 5px;
                    border-radius: 5px;
                    text-align: center;

                    p{
                        cursor: default;
                        padding: 5px;
                        border-radius: 5px;
                        background-color: #0071bd28;
                    }
                    
                }
                .marked{
                    padding: 5px;
                    border-radius: 5px;
                    background-color: rgba(97, 255, 97, 0.7);
                }

                .icon{

                        &:hover{
                        background-color: #0071bd28;
                    }
                }

                
            }

            .right{

                .newLineParagraph{
                    margin-bottom: 15px;
                }
                
                width: calc(100% - 50px);
                margin-left: 20px;
                .fullQuestion{
                    margin-bottom: 20px;
                }

                .tags {
                    margin-bottom: 20px;
                    display: flex;
                    gap: 10px;
                    padding: 5px;

                    .tag {
                        padding: 5px;
                        border-radius: 5px;
                        background-color: #0071bd28;
                    }
                }

                .bottom{
                    display: flex;
                    justify-content: space-between;
                    button{
                        margin-right: 20px;
                    }
                }
            }
        }

        .answer{
            button{
                margin-top: 20px;
            }
        }
    }
}