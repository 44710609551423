.report {
    display: flex;
    height: 100%;
    width: 100%;


    .reportContainer {
        flex: 6;
        padding-bottom: 300px;
        .topButtons{
            margin-top: 10px;

            button{
            margin-left: 20px;
            &.active {
                background-color: #0071bd5d !important;
            }
            }
        }

        .top {
            margin-top: 10px;

            .reportTitle {
                margin-left: 20px;
                color: gray;
                width: 100%;
                font-size: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .main {
                display: flex;

                .statisticsContainer,
                .filterContainer {
                    margin: 20px;
                    padding: 20px;
                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    border-radius: 10px;

                    .generateButton {
                        float: right;
                        cursor: pointer;
                        font-size: 20px;
                        border-color: transparent;
                        background-color: white;
                        text-decoration: none;
                        border-radius: 10px;
                        padding: 5px 10px 5px 10px;
                        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

                        &:hover {
                            background-color: rgba(128, 128, 128, 0.3);
                            transition: all 0.2s ease;
                        }
                    }

                    .dateFilter {
                        align-items: center;
                        margin: 20px;
                        gap: 10px;
                    }

                    .statusFilter {
                        align-items: center;
                        margin: 20px;
                        gap: 10px;
                        display: flex;
                    }

                    .reportFilter {
                        input {
                            margin-top: 8px;
                            height: 34px;
                            max-width: 130px;
                        }

                    }

                    .numberOfOrders {
                        align-items: center;
                        margin: 20px;
                        gap: 20px;
                        display: flex;
                    }
                }
            }

        }

        .profitContainer{
            margin: 20px;
            padding: 20px;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            border-radius: 10px;

            .detailsContainer{
                display: flex;
                justify-content: space-between;
            }
            
            .individualContainer{
                margin: 15px;
            }
        }
    }
}

.order {
    display: flex;
    height: 100%;
    width: 100%;

    .orderContainer {
        flex: 6;
        padding-bottom: 300px;

        .topButtons{
            margin-top: 10px;

            button{
            margin-left: 20px;
            &.active {
                background-color: #0071bd5d !important;
            }
            }
        }
        
        .top {
            margin: 20px;

            .orderSubTitle {
                color: gray;
                font-style: italic;
                margin-left: 40px;
            }

            .orderTitle {
                color: gray;
                width: 100%;
                font-size: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .main {
                display: flex;

                .statisticsContainer,
                .filterContainer {
                    margin: 20px;
                    padding: 20px;
                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    border-radius: 10px;

                    .dateFilter {
                        align-items: center;
                        margin: 20px;
                        gap: 10px;
                        display: flex;
                    }

                    .statusFilter {
                        align-items: center;
                        margin: 20px;
                        gap: 10px;
                        display: flex;
                    }

                    .orderFilter{
                        input{
                            margin-top: 8px;
                            height: 34px;
                            max-width: 130px;
                        }
                        
                    }

                    .numberOfOrders {
                        align-items: center;
                        margin: 20px;
                        gap: 20px;
                        display: flex;
                    }

                    .generateButton {
                        float: right;
                        cursor: pointer;
                        font-size: 20px;
                        border-color: transparent;
                        background-color: white;
                        text-decoration: none;
                        border-radius: 10px;
                        padding: 5px 10px 5px 10px;
                        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

                        &:hover {
                            background-color: rgba(128, 128, 128, 0.3);
                            transition: all 0.2s ease;
                        }
                    }
                }
            }

        }

    }
}